/**
 *
 *
 */
import { sendLog } from '@otto-ec/global-resources/debug'

export class LoggingService {
  constructor(private readonly random: () => number = Math.random) {}

  private static readonly NAMESPACE_CMP = 'eprivacy_cmp'

  /**
 *
 *
 *
 *
 *
 */
  logMessage(message: string, id?: string) {
    const trimmedMessage = this.trimMessage(message)
    if (!trimmedMessage || trimmedMessage.length === 0) {
      return
    }
    const data = {
      message: trimmedMessage,
      ...(id ? { id: this.trimMessage(id, 256) } : {}),
    }
    sendLog(LoggingService.NAMESPACE_CMP, data)
  }

  /*                                                          */
  logError(error: any, ...rest: any[]) {
    if (!(error instanceof Error)) {
      try {
        const errorAsString = JSON.stringify(error)
        this.logMessage(
          'Error argument in loggingService.logError is not of type Error: ' +
            errorAsString
        )
      } catch (e) {
        console.warn('Could not stringify error', e, error)
      }
      return
    }
    const message = this.trimMessage(error.message)
    if (!message || message.length === 0) {
      return
    }
    const data = {
      message: message,
      stack: this.trimMessage(error.stack, 768),
      errorName: error.name,
      ...(rest.length > 0 ? { data: JSON.stringify(rest) } : {}),
    }
    sendLog(LoggingService.NAMESPACE_CMP, data)
  }

  sample(myMessage: string, sampleRate: number) {
    if (this.random() < sampleRate) {
      this.logMessage(myMessage)
    }
  }

  private trimMessage(message?: string, numChars: number = 512) {
    return message?.substring(0, numChars).trim()
  }
}
